import React from 'react'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import '../../../components/component-style/blog.scss'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

const nas_post = ({ data }) => {
    return (
        <Layout>
            <SEO title="Bookcase NAS" />
            <div className="blog-post-container">
                <div className="blog-post">
                    <h1>Bookcase NAS</h1>
                    <h2>2019-09-23</h2>
                    <div className="blog-post-content">
                        <div className="imgCenter">
                            <GatsbyImage
                                image={data.frontNopanel.childImageSharp.gatsbyImageData}
                                alt="NAS overview" />
                            <p>The finished product with the front panel off</p>
                        </div>
                        <br />
                        <p>
                            Some might call me a data hoarder. I have tons of
                            documents, project files, music, recorded audio and
                            video, and, perhaps most noteworthy, many terabytes
                            of RAW photos. I try to limit my usage by deleting
                            bad or near-duplicate photos when I import as well
                            as regularly use{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://dev.yorhel.nl/ncdu">ncdu</a> or
                            something like{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://windirstat.net/">WinDirStat</a>{' '}
                            (for Windows) to see what's taking up disk space and
                            remove if necessary.
                        </p>
                        <p>It's still bad.</p>
                        <h3>Weighing the options</h3>
                        <p>
                            I recently switched pc cases from a big 'ol{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.nzxt.com/products/h440-black">
                                NZXT H440
                            </a>{' '}
                            with 11 3.5" drive bays to the much smaller{' '}
                            <a target="_blank" rel="noopener noreferrer" href="http://www.phanteks.com/Enthoo-Evolv-Shift.html">
                                Evolv Shift
                            </a>
                            , which only has 1 3.5" bay. I love it, but I can no
                            longer get away with just stuffing a bunch of 1-2TB
                            drives in my desktop.
                        </p>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage
                                    image={data.synology.childImageSharp.gatsbyImageData}
                                    alt="Synology DS918+" />
                                <p>Synology DS918+</p>
                            </div>
                            I resigned myself to external storage. My first idea
                            was to get something like the{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.synology.com/en-us/products/DS918+">
                                Synology DS918+
                            </a>
                            , which has 4 drive bays, dual ethernet, and many
                            advanced NAS features. My only issue is that it's
                            over $500 without any drives included. This still
                            seems like the best option to me if you have the
                            cash to spend, but I do not. My next idea was to
                            simply get several external hard drives. I saw some
                            10TB WD Elements drives on sale and figured I could
                            use one for storage and one as a backup. Easy.
                        </p>
                        <p>
                            While researching the drives, however, I found an{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://forums.serverbuilds.net/t/guide-nas-killer-4-0-fast-quiet-power-efficient-and-flexible-starting-at-125/667">
                                interesting forum post
                            </a>{' '}
                            on{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.serverbuilds.net">
                                ServerBuilds.net
                            </a>{' '}
                            that described shucking external hard drives from
                            their enclosures and hooking them to older server or
                            desktop hardware. It turns out that some of these
                            external drives are actually using whitelabeled
                            NAS-grade disks, which is very convenient! Many of
                            the recommended configurations run in the $150-200
                            range before drives, which seems much more
                            reasonable than the prebuilt enclosure route.
                        </p>
                        <h3>The plan</h3>
                        <p>
                            The obvious choice for my enclosure would be my old
                            pc case. It has the drive capacity, and I already
                            own it. There are two problems though:
                            <ul>
                                <li>
                                    The case is in great shape, and part of my
                                    justification for the new case was selling
                                    the old one.
                                </li>
                                <li>
                                    The main reason for the smaller case was so
                                    that the big one wasn't using floorspace
                                </li>
                            </ul>
                        </p>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage image={data.bookcase.childImageSharp.gatsbyImageData} alt="Bookcase" />
                                <p>Is this a server rack?</p>
                            </div>
                            I really just wanted something like the Synology
                            unit that I could stick on a shelf and mostly
                            ignore. So why not stick the custom one on a shelf?
                        </p>
                        <p>I am not a tall man.</p>
                        <p>
                            The top shelf of the Ikea bookcase next to my desk
                            is reachable, but not very usefully so. I often find
                            that things I've put there get forgotton or, when I
                            do take something down, not put back away. It's the
                            perfect spot for something stationary like pictures
                            of loved ones or a high capacity file server.
                        </p>
                        <h3>The build</h3>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage image={data.fanCutout.childImageSharp.gatsbyImageData} alt="Fan cutout" />
                                <p>It's almost round</p>
                            </div>
                            I started with case preparation. The shelves are
                            26.5cm deep, so I set the shelf to a position that
                            left 24cm of vertical space for the components and
                            fans. I'm putting one fan on each end to create a
                            sort of wind tunnel to move air across the
                            components. The fans I'm using are 200mm{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://noctua.at/en/nf-a20-pwm">
                                Noctua NF-A20 PWMs
                            </a>
                            , so I drew some circles on the outer walls and cut
                            them out with a jigsaw. I don't have the steadiest
                            hands, so it would have been more ideal to use a
                            router or massive hole saw, but we work with the
                            tools we have. I also drilled some holes in the
                            front for a cover and through the sides into the
                            shelf itself for sturdier mounting later. Each hole
                            got a threaded m3 insert so that it can easily be
                            taken back apart.
                        </p>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage
                                    image={data.powerSupply.childImageSharp.gatsbyImageData}
                                    alt="Power supply" />
                                <p>PSU positioned near the exhaust</p>
                            </div>
                            To mount the hardware I removed the shelf from the
                            bookcase and drilled some holes for motherboard
                            standoffs. I opted to go for consumer components
                            instead of old server kit because I already had some
                            spare DDR3 RAM, so I went for an Intel{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://ark.intel.com/content/www/us/en/ark/products/59044/intel-desktop-board-dq77mk.html">
                                DQ77MK motherboard
                            </a>{' '}
                            and{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://ark.intel.com/content/www/us/en/ark/products/65701/intel-core-i5-3570s-processor-6m-cache-up-to-3-80-ghz.html">
                                i5-3570S
                            </a>{' '}
                            that were bundled on eBay for $60. The 700 watt
                            power supply that I'm using is roughly 3 times
                            beefier than I need it to be, but I already had it
                            so may as well put it to work. It would be nice to
                            replace the psu at some point with a lower powered
                            modular one to reduce cable clutter
                        </p>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage image={data.m2Board.childImageSharp.gatsbyImageData} alt="Fan cutout" />
                                <GatsbyImage image={data.hbaBoard.childImageSharp.gatsbyImageData} alt="Fan cutout" />
                                <p>M.2 and HBA Cards</p>
                            </div>
                            I have a total of 5 drives in the system. Three of
                            them are the 10TB{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://shop.westerndigital.com/products/external-drives/wd-elements-desktop-usb-3-0-hdd#WDBWLG0100HBK-NESN">
                                WD Elements
                            </a>{' '}
                            drives, shucked, and the other two are small M.2
                            ssds mounted on a{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.newegg.com/p/17Z-00EV-00046">
                                PCIE adapter card
                            </a>{' '}
                            and used as a cache and a boot drive. Since this
                            motherboard only has 2 6Gbps SATA ports, I hooked
                            the storage drives up to a 2-port{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.broadcom.com/products/storage/host-bus-adapters/sas-9210-8i">
                                SAS HBA PCIE card
                            </a>
                            . Each of the sas ports on this card can be split
                            out to 4 SATA 6Gbps ports, so I could add up to 8
                            drives through it.
                        </p>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage
                                    image={data.driveCageFront.childImageSharp.gatsbyImageData}
                                    alt="Drive Cage" />
                                <p>Smells like Christmas</p>
                            </div>
                            All that's really left is to mount everything. I
                            fashioned a drive rack out of some scrap cedar
                            planks, anti-vibration washers, and 3.5"->5.5"
                            adapters to widen the footprint and increase
                            stability.
                        </p>
                        <p>
                            Everything got fastened to the shelf, the fans were
                            mounted on the bookcase, all that was left to do was
                            test it and put the shelf back in. I installed{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.freenas.org/">FreeNAS</a> on
                            the boot drive (which has some honestly lovely{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.ixsystems.com/documentation/freenas/11.3-U2/freenas.html">
                                documentation
                            </a>
                            ), and got my pool set up in RAID-Z1. Everything
                            showed up, so time to clean up.
                        </p>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage
                                    image={data.networkSwitch.childImageSharp.gatsbyImageData}
                                    alt="Network Switch" />
                                <p>ʞuᴉl-dʇ</p>
                            </div>
                            The screws securing the shelf at one end are spaced
                            at the same distance as the mounting holes on a{' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.tp-link.com/us/home-networking/8-port-switch/tl-sg1008d/">
                                cheap network switch
                            </a>{' '}
                            that I have, which allows a direct 1Gbps connection
                            between my pc and the NAS while also connecting both
                            to the local network.
                        </p>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage
                                    image={data.powerButton.childImageSharp.gatsbyImageData}
                                    alt="Power supply" />
                            </div>
                            I also cut a hole for an LED power button so that it
                            can be controlled with the cover on. The front panel
                            is a 12x36" plexiglass sheet cut down to size, and
                            the power/ethernet cables run out the back to give
                            it a clean look from most angles.
                        </p>
                        <h3>Testing</h3>
                        <p>
                            <div className="imgRight">
                                <GatsbyImage
                                    image={data.freenasDrives.childImageSharp.gatsbyImageData}
                                    alt="FreeNAS drive detection" />
                                <p>The path of the righteous NAS</p>
                            </div>
                            Firing up the FreeNAS control panel, I made sure
                            that my drives and memory were all still detected,
                            and after letting it run for about a day, I checked
                            the temps to make sure they never spiked. Because of
                            the data parity offered by RAID-Z1, I have about 18
                            usable terabytes out of the 30TB total raw capacity,
                            which is more than enough for the time being.
                        </p>
                        <p>
                            <div className="imgLeft">
                                <GatsbyImage image={data.speedTest.childImageSharp.gatsbyImageData} alt="Copy test" />
                                <p>107MB/s ≈ 856Mbps</p>
                            </div>
                            To check the performance, I tried copying a couple
                            of OS isos from my local drive to the NAS and got a
                            pretty consistant ~850Mbps, which is close enough to
                            gigabit for me! Copying files from elsewhere on the
                            network was a bit slower; I think the bottleneck
                            there is our router.
                        </p>
                        <p>
                            In the future I'd like to set up a small monitor on
                            it to display stats, alerts, and maybe pictures of
                            my dog.
                        </p>
                        <div className="imgCenter">
                            <GatsbyImage
                                image={data.frontWithpanel.childImageSharp.gatsbyImageData}
                                alt="NAS overview" />
                        </div>
                        <br />
                        <p>
                            If you&#39;ve done/want to do a project like this or
                            if you have any questions or comments, please{' '}
                            <a target="_blank" rel="noopener noreferrer" href="/contact">reach out</a>!
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default nas_post

export const query = graphql`{
  bookcase: file(relativePath: {regex: "/nas/bookcase.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  synology: file(relativePath: {regex: "/nas/synology.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  speedTest: file(relativePath: {regex: "/nas/speed_test.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  freenasDrives: file(relativePath: {regex: "/nas/freenas_drives.png/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  angleView: file(relativePath: {regex: "/nas/angle_view.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  driveCageBack: file(relativePath: {regex: "/nas/drive_cage_back.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  driveCageClose: file(relativePath: {regex: "/nas/drive_cage_close.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  driveCageFront: file(relativePath: {regex: "/nas/drive_cage_front.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  drive_cage_mounted: file(relativePath: {regex: "/nas/drive_cage_mounted.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  fanCutout: file(relativePath: {regex: "/nas/fan_cutout.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  frontNopanel: file(relativePath: {regex: "/nas/front_nopanel.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  frontWithpanel: file(relativePath: {regex: "/nas/front_withpanel.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  hbaBoard: file(relativePath: {regex: "/nas/hba_board.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  m2Board: file(relativePath: {regex: "/nas/m2_board.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  networkSwitch: file(relativePath: {regex: "/nas/network_switch.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  powerButton: file(relativePath: {regex: "/nas/power_button.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  powerSupply: file(relativePath: {regex: "/nas/power_supply.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  threadedInsert: file(relativePath: {regex: "/nas/threaded_insert.jpg/"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`
